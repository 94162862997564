import { Badge, BadgeProps, CloseButton, Flex, createStyles } from '@mantine/core'
import { IconChevronRight, IconPlus } from '@tabler/icons'
import React from 'react'

import { Typography, Variant } from '~/components/shared/text/Typography'

const useStyle = createStyles(
  (_theme, { noPointer, noBorder }: { noPointer: boolean; noBorder: boolean }) => ({
    root: {
      border: noBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.1)',
      width: 'fit-content',
      height: 35,

      '&:hover': {
        cursor: noPointer ? 'default' : 'pointer',
      },
    },
    rightSection: {
      marginLeft: 6,
      display: 'flex',
    },
  })
)

type ThatchPillProps = {
  onClick?: () => void
  label: string
  type: 'item' | 'selector'
  selected?: boolean
  noPointer?: boolean
  showChevronIcon?: boolean
  filled?: boolean
  labelVariant?: Variant
  fill?: string
} & BadgeProps
export const ThatchPill: React.FC<ThatchPillProps> = ({
  type,
  onClick,
  label,
  selected,
  noPointer,
  showChevronIcon,
  filled,
  labelVariant,
  fill,
  ...props
}) => {
  const { classes } = useStyle({ noPointer: !!noPointer, noBorder: !!filled })

  return (
    <Badge
      color="appBlack.0"
      bg={filled ? 'appPaper.0' : fill ? fill : undefined}
      classNames={{ root: classes.root, rightSection: classes.rightSection }}
      px={12}
      variant={selected ? 'filled' : 'outline'}
      rightSection={
        type === 'selector' ? (
          <IconPlus size={18} />
        ) : (
          selected && (
            <CloseButton
              size={18}
              color="appWhite.0"
              variant="transparent"
            />
          )
        )
      }
      onClick={onClick}
      {...props}
    >
      <Flex
        gap={3}
        align="center"
      >
        <Typography
          variant={labelVariant || 'caption'}
          tt="none"
          sx={{ fontSize: '16px !important' }}
        >
          {label}
        </Typography>
        {showChevronIcon && (
          <IconChevronRight
            width={18}
            height={18}
          />
        )}
      </Flex>
    </Badge>
  )
}
